var _ = require("lodash");
// -------------------------------------
// controlls the mobile menu icon burger
// -------------------------------------

$(document).ready(function() {
  $("#nav-icon").click(function() {
    // Toggles the navicon burger appearance
    $(this).toggleClass("open");
    $(".menu-search-wrapper").toggleClass("open");
    $(".search-wrap").toggleClass("open");
    // Toggles the overlay when opening/closing the menu
    // $("body").toggleClass("menu-active");
  });
});

import List from "list.js";

// Sort emails from customers with completed purchaches

var options = {
  valueNames: ["email"]
};

var userList = new List("customeremails", options);
var noItems = $(
  '<ul class="list"><li">Hittade inga kunder med genomförda ordrar. Försök igen.</li></ul>'
);

userList.on("updated", function(list) {
  if (list.matchingItems.length == 0) {
    $(list.list).append(noItems);
  } else {
    noItems.detach();
  }
});

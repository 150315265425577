import "owl.carousel";
// -------------------------------
// Inspiration articles slider block
// -------------------------------
var slider = $(".inspiration-articles");
slider.owlCarousel({
  center: false,
  items: 1,
  loop: true,
  margin: 20,
  nav: false,
  dots: true,
  autoHeight: true,
  navText: ["", ""],
  responsive: {
    767: {
      items: 2,
    },
    1024: {
      items: 3,
      margin: 30,
      loop: false, // no slider/loop on desktop
      dots: false, // no slider/nav on desktop
    },
  },
});

import "owl.carousel";
// ------------------------------------------------
// Slider product images
// ------------------------------------------------
var slider = $(".product-gallery");
var amountHeaderImages = slider.find("img").length;
slider.owlCarousel({
  items: 1,
  center: true,
  autoplay: false,
  lazyLoad: true,
  loop: amountHeaderImages > 1, // if only 1 item no loop
  margin: 10,
  nav: true,
  dots: true,
  navText: ["", ""],
});

// ------------------------------------------------
// Slider product items
// ------------------------------------------------
var slider = $(".product-gallery-items");
var amountProducts = slider.find(".product-card").length; // count products
slider.owlCarousel({
  center: false,
  items: 2,
  loop: true,
  margin: 8,
  nav: true,
  autoHeight: true,
  navText: ["", ""],
  responsive: {
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
    1280: {
      items: 5,
      loop: amountProducts > 5, // if only 5 item no loop
      nav: amountProducts > 5, // if only 5 item no nav
      dots: amountProducts > 5, // if only 5 item no dots
    },
  },
});

var slider = $(".product-gallery-items-inspiration");
var amountProducts = slider.find(".product-card").length; // count products
slider.owlCarousel({
  center: false,
  items: 2,
  loop: true,
  margin: 8,
  nav: true,
  navText: ["", ""],
  responsive: {
    768: {
      items: 2,
    },
    1280: {
      items: 3,
      loop: amountProducts > 3, // if only 3 item no loop
      nav: amountProducts > 3, // if only 3 item no nav
      dots: amountProducts > 3, // if only 3 item no dots
    },
  },
});

// ------------------------------------------------
// Opens fullscreen slider and locks body-scroll
// ------------------------------------------------
$(window).on("load resize", function () {
  if ($(window).width() > 768) {
    $("#open-fullscreen-gallery").click(function () {
      $(".gallery-view").show();
      $("body").addClass("lock-scroll");
    });

    $("#close-fullscreen-gallery").click(function () {
      $(".gallery-view").hide();
      $("body").removeClass("lock-scroll");
    });
  }

  // Prevent click action
  $(".non-clickable-image").click(function (e) {
    e.preventDefault();
  });
});

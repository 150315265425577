(function($) {
  if($('.block-carousel').length) {
    $.each($('.block-carousel'), function() {
      var carousel = $(this);

      if (carousel.find('.item').length > 1) {

        // Carousel Properties
        var slideSpeed = 400;
        var autoplay = 0;
        var arrows = 0;
        var dots = 0;

        slideSpeed = carousel.attr('data-speed') ? parseInt(carousel.attr('data-speed')) : slideSpeed;
        autoplay = carousel.attr('data-autoplay') ? parseInt(carousel.attr('data-autoplay')) : autoplay;
        arrows = carousel.attr('data-arrows') ? parseInt(carousel.attr('data-arrows')) : arrows;
        dots = carousel.attr('data-dots') ? parseInt(carousel.attr('data-dots')) : dots;

        carousel.owlCarousel({
          items: 1,
          center: false,
          loop: true,
          lazyLoad: false,
          autoplayHoverPause: true,
          slideSpeed: slideSpeed,
          autoplay: autoplay,
          nav: arrows,
          dots: dots,
        });
      }
    });
  }
})(jQuery);

// -------------------------------
// FAQ questions. Answers opens in accordion below question
// -------------------------------

$(document).ready(function ($) {
  $("#faq-questions .faq .faq-trigger").on("click", function () {
    $("#product-tabs").removeAttr("style");
    $(this).parent().toggleClass("active");
    $(this).next(".faq-foldout").toggle();
    var grandparentHeight =
      $(this).closest(".product-tabs__item-content").height() + 120;
    $("#product-tabs").height(grandparentHeight);
  });
});

import "owl.carousel";
// initialize sub categories slider
$(window).on("load resize", function(e) {
  if ($(window).width() > 768) {
    initSubCategoriesSlider();
  } else {
    initStopCarsousel();
  }
});

// function to initialize sub categories slider
window.initSubCategoriesSlider = function() {
  var slider = $(".sub-categories");
  slider.owlCarousel({
    items: 7,
    loop: false,
    // autoHeight: true,

    autoWidth: false,
    nav: true,
    responsive: {
      767: {
        items: 4
      },
      1023: {
        items: 6
      }
    }
  });
};

//function to stop carousel
window.initStopCarsousel = function() {
  var owl = $(".sub-categories");
  owl.trigger("destroy.owl.carousel");
  owl.addClass("off");
};

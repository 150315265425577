import List from "list.js";

// ----------------------------------------------------
// script to controll filter on categoy pages
// ----------------------------------------------------
$(document).ready(function($) {
  // -----------------------------------------------------------------

  // send ajax request on load-more click
  $(".ajax-load-more").on("click", function(event) {
    event.preventDefault();
    makeAjaxCall();
  });

  // -----------------------------------------------------------------

  // filter on checkbox menu
  $(document).on(
    "change",
    ".filter-page .js-filter input[type=checkbox]",
    function(event) {
      event.preventDefault();
      var checkbox = $(this);
      var filterName = checkbox.attr("name");
      var filterMenu = $(this).parents(
        "[data-product-type=" + filterName + "]"
      );
      var filterSlug = event.target.value;
      var productList = $("#product-list");
      var filterCategories = filterMenu.attr("data-filter");
      productList.attr("data-page", "1"); // reset page number to next page
      if (checkbox.is(":checked")) {
        if (filterCategories.search(filterSlug) === -1) {
          filterCategories = filterCategories + "," + filterSlug;
        }
      } else {
        filterCategories = filterCategories.replace(filterSlug, "");
      }

      filterCategories.trim(); // trim any spaces
      filterCategories = filterCategories.replace(",,", ","); // remove double comma
      filterCategories = filterCategories.replace(/^,?/, ""); // remove first comma
      filterCategories = filterCategories.replace(/,$/, ""); // remove last comma
      filterMenu.attr("data-filter", filterCategories);

      setTimeout(() => {
        let replace = 1; // true
        makeAjaxCall(replace);
      }, 200);
    }
  );

  // -----------------------------------------------------------------

  // filter on filter link
  $(document).on(
    "click",
    ".sidebar-filter-menu .js-link .filter-link",
    function(event) {
      event.preventDefault();
      $(this)
        .parent()
        .toggleClass("active");

      var link = $(this);
      var filterName = link.attr("data-name");
      var filterMenu = $(this).parents(
        "[data-product-type=" + filterName + "]"
      );
      var filterSlug = link.attr("data-value");
      var productList = $("#product-list");
      var filterCategories = filterMenu.attr("data-filter");
      productList.attr("data-page", "1"); // reset page number to next page
      if (link.parent().hasClass("active")) {
        if (filterCategories.search(filterSlug) === -1) {
          filterCategories = filterCategories + "," + filterSlug;
        }
      } else {
        filterCategories = filterCategories.replace(filterSlug, "");
      }

      filterCategories.trim(); // trim any spaces
      filterCategories = filterCategories.replace(",,", ","); // remove double comma
      filterCategories = filterCategories.replace(/^,?/, ""); // remove first comma
      filterCategories = filterCategories.replace(/,$/, ""); // remove last comma
      filterMenu.attr("data-filter", filterCategories);

      setTimeout(() => {
        let replace = 1; // true
        makeAjaxCall(replace);
      }, 200);
    }
  );

  // -----------------------------------------------------------------

  // list filter options
  var options = {
    valueNames: [
      "sortable-name",
      "sortable-date",
      "sortable-price",
      "sortable-sales"
    ],
    listClass: "product-list",
    sortClass: "product-sorter",
    indexAsync: true
  };

  var productLists = new List("js-sortable-wrapper", options);
  productLists.alphabet =
    "0123456789AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvXxYyZzÅåÄäÖö";
  var defaultSorting = $(".js-sorting-arrow.product-sorter").attr("data-sort");
  if ($("#js-sortable-wrapper").length) {
    setTimeout(function() {
      productLists.sort(defaultSorting, {
        order: "desc"
      });
    }, 150);
  }

  // make ajax call
  function makeAjaxCall(replace = 0) {
    var productList = $("#product-list");
    var filterMenuElm = $("#product-filter-menu");
    var security = productList.attr("data-check");
    var page = productList.attr("data-page");
    var limit = productList.attr("data-limit");
    var cat = productList.attr("data-cat");
    var filters = getFilterList();
    var orderBy = "sales";
    var currentOrderBy = $(
      ".product-custom-sorting .sorting-list .active .product-sorter"
    );
    if (currentOrderBy.length) {
      var _orderBy = currentOrderBy.attr("data-sort");
      orderBy = _orderBy.replace("sortable-", "");
    }
    var currentOrde = $(
      ".product-custom-sorting .sorting-arrow.product-sorter"
    );
    var order = "asc";
    if (currentOrde.length) {
      if (currentOrde.hasClass("desc")) {
        order = "desc";
      }
    }

    $.ajax({
      url: adminURL,
      type: "POST",
      dataType: "json",
      data: {
        action: "loadmore_products",
        security: security,
        page: page,
        limit: limit,
        filters: filters,
        replace: replace,
        cat: cat,
        order_by: orderBy,
        order: order
      },
      beforeSend: function() {
        $("body").addClass("product-loading");
      },
      success: function(response) {
        $("body").removeClass("product-loading");

        // update next page count
        if (response.next_page) {
          productList.attr("data-page", response.next_page);
          $(".ajax-load-more")
            .parent()
            .show();
        } else {
          // hide loadmore button if there are no next page to load
          $(".ajax-load-more")
            .parent()
            .hide();
        }

        // append or replace new products
        if (response.replace === true) {
          productList.html(response.products);
        } else {
          productList.append(response.products);
        }

        // hide filter menu with no products on them
        if (response.active_filters) {
          $.each(response.active_filters, function(key, filters) {
            var excludeClasses = [];
            $.each(filters, function(el, count) {
              excludeClasses.push(el);
              let option = filterMenuElm.find(
                "[data-product-type=" + key + "] ." + el
              );
              option.find(".count").text("(" + count + ")");
              option.parents("li").show();
              option.show();
            });
            hideOtherOptions(excludeClasses);
          });
        }
      },
      error: function() {
        $("body").removeClass("product-loading");
      }
    });
  }

  function getFilterList() {
    var filters = [];
    $("[data-product-type]").each(function() {
      var filter = {};
      var type = $(this).attr("data-product-type");
      var value = $(this).attr("data-filter");
      filter["type"] = type;
      filter["value"] = value;
      filters.push(filter);
    });

    return filters;
  }

  function hideOtherOptions(excludeClasses) {
    $(".js-filter li").each(function() {
      var li = $(this);
      var classes = li.attr("class").split(" ");
      $.each(excludeClasses, function(i, el) {
        if ($.inArray(el, classes) !== -1) {
          li.show();
          li.parents("li").show();
          return false;
        } else {
          li.hide();
        }
      });
    });
    // count number of li and set class to hide if more than 5 after Ajax call
    var $getFilterUl = $(".sub-menu");
    $($getFilterUl).each(function() {
      var $getLi = $(this).find("li:visible").length;
      if ($getLi > 5) {
        var $getParent = $(this).parent("li");
        $($getParent).addClass("toggleFilter");
        $(this).addClass("hideFilter");
      } else {
        var $getParent = $(this).parent("li");
        $($getParent).removeClass("toggleFilter");
        $(this).removeClass("hideFilter");
      }
    });
  }

  // -----------------------------------------------------------------

  // get each filter meny and count filters. Hide if more than 5
  var $getFilterUl = $(".product-filter-menu .sub-menu");

  $($getFilterUl).each(function() {
    // Define show more/show less variables
    var $showMore = null;
    var $showLess = null;

    // Check which language
    if (siteLang === "NO") {
      $showMore = "VIS ALLE +";
      $showLess = "VIS FÆRRE +";
    }else if (siteLang === "FI") {
      $showMore = "NÄYTÄ KAIKKI +";
      $showLess = "NÄYTÄ VÄHEMMÄN +";
    } else {
      $showMore = "VISA ALLA +";
      $showLess = "VISA FÄRRE +";
    }
    var $getLi = $(this).find("li").length;
    if ($getLi > 5) {
      var $getParent = $(this).parent("li");
      $($getParent).addClass("toggleFilter");
      $(this).addClass("hideFilter");
      $(
        '<span class="toggle-filter-trigger showTrigger">' +
          $showMore +
          "</span>"
      ).appendTo($getParent);
      $(
        '<span class="toggle-filter-trigger">' + $showLess + "</span>"
      ).appendTo($getParent);
    } else {
      // none bro
    }
  });

  // -----------------------------------------------------------------

  // toggle filter on click trigger
  $(".toggle-filter-trigger").click(function() {
    var $getParent = $(this).parent("li");
    var $getFilterlist = $($getParent).find("ul");
    $($getFilterlist)
      .toggleClass("hideFilter")
      .toggleClass("showClass");
    $($getParent)
      .find(".toggle-filter-trigger")
      .toggleClass("showTrigger");
  });

  // -----------------------------------------------------------------

  // toggle filter menu on mobile devices
  $(".sidebar-filter-menu > h3").on("click", function() {
    $("body").toggleClass("filter-menu-active");
  });

  $(".sidebar-filter-menu .close-filter").on("click", function(e) {
    e.preventDefault();
    $("body").removeClass("filter-menu-active");
  });

  // -----------------------------------------------------------------

  // toggle highlighted label
  $("input[type=checkbox]").on("click", function() {
    $(this)
      .closest("label")
      .toggleClass("highlighted");
  });

  $(".product-custom-sorting .sorting-arrow").on("click", function(e) {
    e.preventDefault();
    $("#product-list").attr("data-page", 1);

    var arrow = $(this);
    var sortingElm = $(this).parent();

    if (sortingElm.hasClass("sorting-active")) {
      sortingElm.removeClass("sorting-active");
    }

    var sortItem = sortingElm.find(
      'li [data-sort="' + arrow.attr("data-sort") + '"]'
    );
    var title = sortItem.text();
    var opener = sortingElm.find(".opener");
    opener.find(".text").text(title);
    sortItem
      .parent()
      .siblings()
      .removeClass("active");
    sortItem.parent().addClass("active");

    // toggle class 'asc' and 'desc'
    if (arrow.hasClass("asc")) {
      arrow.removeClass("asc");
      arrow.addClass("desc");
    } else {
      arrow.removeClass("desc");
      arrow.addClass("asc");
    }

    // make ajax call to get data from server
    setTimeout(function() {
      makeAjaxCall(1);
    }, 100);
  });

  /**
   * Custom sorting on preloaded data on search page now
   */
  $(".js-product-custom-sorting .js-sorting-list .product-sorter").on(
    "click",
    function(e) {
      var wrapper = $(".js-product-custom-sorting");
      var item = $(this);
      var sortData = item.data("sort");
      var sortTitle = item.text();
      var opener = wrapper.find(".opener");
      var arrow = wrapper.find(".js-sorting-arrow");

      // active current item and deactivate rest
      item
        .parent()
        .siblings()
        .removeClass("active");
      item.parent().addClass("active");

      // change opener text
      opener.find(".text").text(sortTitle);
      arrow.attr("data-sort", sortData);
      arrow.addClass("asc");

      // deactivate wrapper
      wrapper.removeClass("sorting-active");

      $(this)
        .parents(".js-product-custom-sorting")
        .removeClass("sorting-active");
    }
  );

  $(".product-custom-sorting .sorting-list .product-sorter").on(
    "click",
    function(e) {
      e.preventDefault();
      $("#product-list").attr("data-page", 1);

      var wrapper = $(".product-custom-sorting");
      var item = $(this);
      var sortData = item.data("sort");
      var sortTitle = item.text();
      var opener = wrapper.find(".opener");
      var arrow = wrapper.find(".sorting-arrow");

      // active current item and deactivate rest
      item
        .parent()
        .siblings()
        .removeClass("active");
      item.parent().addClass("active");

      // change opener text
      opener.find(".text").text(sortTitle);
      arrow.attr("data-sort", sortData);

      // deactivate wrapper
      wrapper.removeClass("sorting-active");

      // if current link doesn't have class 'asc' or 'desc', it will be sorted in 'asc' first
      if (!arrow.hasClass("asc") && !arrow.hasClass("desc")) {
        // it will be sorted in asc first
        wrapper.addClass("sorted-asc");
        arrow.addClass("asc");
      }

      // if current link have class 'asc' it will be sorted in 'desc'
      if (item.hasClass("asc")) {
        // it will be sorted in desc
        wrapper.removeClass("sorted-asc").addClass("sorted-desc");
        arrow.removeClass("asc").addClass("desc");
      }

      // if current link have class 'desc' it will be sorted in 'asc'
      if (item.hasClass("desc")) {
        // it will be sorted in desc
        wrapper.removeClass("sorted-desc").addClass("sorted-asc");
        arrow.removeClass("desc").addClass("asc");
      }

      // make ajax call to get data from server
      setTimeout(function() {
        makeAjaxCall(1);
      }, 100);
    }
  );
});

// Watch header height to catch dynamic height changes
function updateHeaderHeight() {
  const headerHeight = document.querySelector("header").offsetHeight; // Get dynamic header height
  document.documentElement.style.setProperty(
    "--header-height",
    `${headerHeight}px`
  ); // Update CSS-variable
}

// On content loaded
document.addEventListener("DOMContentLoaded", updateHeaderHeight);

// On window resize
window.addEventListener("resize", updateHeaderHeight);

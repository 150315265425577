/**
 * SEX EDUCATION MENU FOR MAIN PAGE AND SUBPAGES
 */

// Add active class to sex education menu
$(document).ready(function ($) {
  $(".sidebar-menu a").each(function () {
    // If menu url is same as window location url add active class
    if (this.href == window.location.href) {
      $(this).addClass("sidebar-menu-item--active");
    }
  });

  // Toggle submenu and submenu description on mobile
  $(".box-menu__sub-menu-trigger").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("sub-menu-trigger--active");
    $(this).parent().find(".box-sub-menu").slideToggle(100);
    $(this).parent().find(".box-sub-menu__description").slideToggle(100);
  });

  // Add horizontal divider after certain amount of list items depending on screen size
  var screensize = $(window).width();
  if (screensize > 549 && screensize < 1023) {
    $("<hr class='divider box-menu__divider small'>").insertAfter(
      ".box-menu li:not(:last-of-type).box-menu__item:nth-child(2n+2)"
    );
  }
  if (screensize > 1023 && screensize < 1539) {
    $("<hr class='divider box-menu__divider large'>").insertAfter(
      ".box-menu li:not(:last-of-type).box-menu__item:nth-child(3n+3)"
    );
  }
  if (screensize > 1539) {
    $("<hr class='divider box-menu__divider xxlarge'>").insertAfter(
      ".box-menu li:not(:last-of-type).box-menu__item:nth-child(4n+4)"
    );
  }
});

// Change horizontal divider on resize depending on screen size
$(window).resize(function () {
  var screensize = $(window).width();
  if (screensize < 550) {
    // Remove all dividers from DOM on mobile
    $(".divider.box-menu__divider.small").remove();
    $(".divider.box-menu__divider.large").remove();
    $(".divider.box-menu__divider.xxlarge").remove();
  }
  if (screensize > 549 && screensize < 1023) {
    // Remove incorrect dividers from DOM
    $(".divider.box-menu__divider.large").remove();
    $(".divider.box-menu__divider.xxlarge").remove();
    // Insert correct dividers on resize
    if ($(".divider.box-menu__divider.small").length) {
      //do nothing if divider is present
    } else {
      //insert divider if not present
      $("<hr class='divider box-menu__divider small'>").insertAfter(
        ".box-menu li:not(:last-of-type).box-menu__item:nth-child(2n+2)"
      );
    }
  }
  if (screensize > 1023 && screensize < 1539) {
    // Remove incorrect dividers from DOM
    $(".divider.box-menu__divider.small").remove();
    $(".divider.box-menu__divider.xxlarge").remove();
    // Insert correct dividers on resize
    if ($(".divider.box-menu__divider.large").length) {
      //do nothing if divider is present
    } else {
      //insert divider if not present
      $("<hr class='divider box-menu__divider large'>").insertAfter(
        ".box-menu li:not(:last-of-type).box-menu__item:nth-child(3n+3)"
      );
    }
  }
  if (screensize > 1539) {
    // Remove incorrect dividers from DOM
    $(".divider.box-menu__divider.small").remove();
    $(".divider.box-menu__divider.large").remove();
    // Insert correct dividers on resize
    if ($(".divider.box-menu__divider.xxlarge").length) {
      //do nothing if divider is present
    } else {
      //insert divider if not present
      $("<hr class='divider box-menu__divider xxlarge'>").insertAfter(
        ".box-menu li:not(:last-of-type).box-menu__item:nth-child(4n+4)"
      );
    }
  }
});

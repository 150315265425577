// -------------------------------
// toggle card and list design
// -------------------------------
$(".display-list").click(function() {
  $(this)
    .siblings()
    .removeClass("active");
  $(this).addClass("active");
  $(".product-list:not(.slider)")
    .removeClass("card")
    .addClass("list");
});

$(".display-card").click(function() {
  $(this)
    .siblings()
    .removeClass("active");
  $(this).addClass("active");
  $(".product-list:not(.slider)")
    .removeClass("list")
    .addClass("card");
});

// -------------------------------
// toogle footer menu in mobile view but NOT social icon list
// -------------------------------

$(document).ready(function ($) {
  $('.footer-menu .menu-item-has-children:not(.social)').click(function () {
    var thisMenu = $(this);
    $(thisMenu).find('.sub-menu').toggleClass('active');
    $(this).closest('.plus-sign').toggleClass('active');

  });

});



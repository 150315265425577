gsap.registerPlugin(ScrollTrigger);
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

if (document.querySelector(".js-animate-fadein")) {
  const fadeins = gsap.utils.toArray(".js-animate-fadein");
  gsap.set(fadeins, { opacity: 0 });
  setTimeout(function () {
    ScrollTrigger.batch(fadeins, {
      onEnter: (batch) =>
        gsap.to(batch, { opacity: 1, stagger: 0.1, duration: 2.5 }),
    });
  }, 2000); //delay is in milliseconds
}

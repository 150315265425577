import Mustache from "mustache";

(function($) {
    $(".search-trigger").click(function(){
        $(".searchform-prod").toggleClass("si-width");
        $(".searchform-prod input").toggleClass("si-width si-padding si-border");
        $(".search-trigger").toggleClass("si-active");
    })

    //toggle dropdown on keyup
    $("#search-prod-input").on(
        "keyup input focus",
        delay(function(e) {
            var searchString = $(this)
                .val()
                .replace(/\s+/g, " ");
            var loading = $(".search-loading");
            var searchWrapper = $(".search-result-wrap");
            if (searchString.length > 1) {
                searchWrapper.addClass("active");
                $.ajax({
                    url: adminURL,
                    type: 'POST',
                    data: { action: 'header_search', keyword: searchString },
                    success: function(data) {
                        $('#search-results').html(data.content);
                    }
                });
            } else {
                searchWrapper.removeClass("active");
            }
        }, 300)

    );

    // sets delay from https://goo.gl/eQHzWv
    function delay(callback, ms) {
        var timer = 0;
        return function() {
            var context = this,
                args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function() {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
})(jQuery);

// CSS
import "./main.scss";

// Waypoint
import "./javascript/jquery.waypoints.min";

// Image lazy loading
import "lazysizes";

// Set datepicker data
import "./javascript/datepicker";

// Custom jquery
import "./javascript/header";
import "./javascript/burger";
import "./javascript/calendar";
import "./javascript/faq";
import "./javascript/filter";
import "./javascript/footer-menu";
import "./javascript/hero-banner";
import "./javascript/pregnancy-calendar";
import "./javascript/primary-menu";
import "./javascript/product-gallery";
import "./javascript/product-tabs";
import "./javascript/readmore";
import "./javascript/scroll";
import "./javascript/search";
import "./javascript/site-overlay";
import "./javascript/sort-customer-emails";
import "./javascript/sort";
import "./javascript/sub-categories";
import "./javascript/top-articles";
import "./javascript/video";
import "./javascript/latest-inspiration-articles";

// NORWAY sex education menu JS
import "./javascript/sex-education-menu";
import "./javascript/youtube-video";

// New blocks 2023
import "./javascript/fullwidth-slider";
import "./javascript/tooltip";
import "./javascript/gif-animation";
import "./javascript/scrolltrigger";

// Cookiebot embeds
import CookiebotEmbeds from "cookiebot-embeds";

new CookiebotEmbeds({
  showSourceURL: true,
  headingText: {
    default: cookiebotIframeTitle,
    youtube: cookiebotIframeTitleYt,
  },
  cookieCategoriesTitle: {
    marketing: marketingTitle,
    statistics: statisticsTitle,
    preferences: preferencesTitle,
  },
  acceptButtonText: cookiebotIframeRequiredBtnTxt,
  openCookiebotSettingsButtonText: cookiebotIframeOpenSettingsBtnTxt,
  background: "white",
  textColor: "white",
  buttonBackgroundColor: "#000",
  buttonBackgroundColorHover: "#000",
  buttonTextColor: "white",
  gap: "12px",
  customCSS: `
    body {
        margin: 0;
        padding: 36px 20px 20px;
        backdrop-filter: blur(3px);
        trsansition: all 0.3s ease;
    }

    body,
    body .loading__overlay {
        background: rgb(45, 43, 38, 0.85);
    }

    .btn {
        transition: all 0.3s ease;
    }

    .btn.btn--accept-required-cookies {
        background: #db002e;
    }

    .btn:hover {
        opacity: .75;
    }
    `,
});

// -------------------------------
// Toggle read more read less
// -------------------------------

$(document).ready(function () {
  $(".js-read-more").click(function () {
    $(".read-more, .shorten-text").toggleClass("hideme showme");
    $(".read-more").toggleClass("no-background");
    if ($(window).width() < 768) {
      $("html, body").animate(
        {
          scrollTop: $("#scroll-to-me").offset().top + -85,
        },
        "slow"
      );
    }
    if ($(window).width() > 768) {
      $("html, body").animate(
        {
          scrollTop: $("#scroll-to-me").offset().top + -160,
        },
        "slow"
      );
    }
  });
});

// -------------------------------
// Toggle read more read less product mobile
// -------------------------------

$(document).ready(function () {
  $(".js-read-more-product").click(function () {
    $(".read-more, .shorten-text").toggleClass("hideme showme");
    $(".read-more").toggleClass("no-background");
    if ($(window).width() < 768) {
      $("html, body").animate(
        {
          scrollTop: $("#scroll-to-me-product").offset().top + -85,
        },
        "slow"
      );
    }
  });
});

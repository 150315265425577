import datepickerFactory from "jquery-datepicker";
import datepickerSVFactory from "jquery-datepicker/i18n/jquery.ui.datepicker-sv";
import datepickerNOFactory from "jquery-datepicker/i18n/jquery.ui.datepicker-no";
import datepickerFIFactory from "jquery-datepicker/i18n/jquery.ui.datepicker-fi";

// Just pass your jquery instance and you're done
datepickerFactory($);
datepickerSVFactory($);
datepickerNOFactory($);
datepickerFIFactory($);

if ($("#datepicker").length) {
  $(function() {
    // If norwegian
    if (siteLang === "NO") {
      $("#datepicker").datepicker({
        closeText: "Lukk",
        prevText: "&#xAB;Forrige",
        nextText: "Neste&#xBB;",
        currentText: "I dag",
        monthNames: [
          "januar",
          "februar",
          "mars",
          "april",
          "mai",
          "juni",
          "juli",
          "august",
          "september",
          "oktober",
          "november",
          "desember"
        ],
        monthNamesShort: [
          "jan",
          "feb",
          "mar",
          "apr",
          "mai",
          "jun",
          "jul",
          "aug",
          "sep",
          "okt",
          "nov",
          "des"
        ],
        dayNamesShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
        dayNames: [
          "søndag",
          "mandag",
          "tirsdag",
          "onsdag",
          "torsdag",
          "fredag",
          "lørdag"
        ],
        dayNamesMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
        weekHeader: "Uke",
        dateFormat: "dd.mm.yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
      });
      // If swedish
    } else {
      $("#datepicker").datepicker({
        closeText: "Stäng",
        prevText: "&#xAB;Förra",
        nextText: "Nästa&#xBB;",
        currentText: "Idag",
        monthNames: [
          "januari",
          "februari",
          "mars",
          "april",
          "maj",
          "juni",
          "juli",
          "augusti",
          "september",
          "oktober",
          "november",
          "december"
        ],
        monthNamesShort: [
          "jan.",
          "feb.",
          "mars",
          "apr.",
          "maj",
          "juni",
          "juli",
          "aug.",
          "sep.",
          "okt.",
          "nov.",
          "dec."
        ],
        dayNamesShort: ["sön", "mån", "tis", "ons", "tor", "fre", "lör"],
        dayNames: [
          "söndag",
          "måndag",
          "tisdag",
          "onsdag",
          "torsdag",
          "fredag",
          "lördag"
        ],
        dayNamesMin: ["sö", "må", "ti", "on", "to", "fr", "lö"],
        weekHeader: "Ve",
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
      });
    }
  });
}

$(document).ready(function () {
  $(".video-wrapper").on("click", function () {
    $(this).addClass("no-bg-img");

    const ytPlayerConfig = "&autoplay=1&loop=1&rel=0&showinfo=0&fs=0";

    // check if the video iframe is blocked by Cookiebot and has data-cookieblock-src attribute
    if ($(this).find(".youtube-video").attr("data-cookieblock-src")) {
      $(this).find(".youtube-video")[0].setAttribute("data-cookieblock-src", $(this).find(".youtube-video").attr("data-cookieblock-src") + ytPlayerConfig);
    } else {
      $(this).find(".youtube-video")[0].src += ytPlayerConfig;
    }
  });
});

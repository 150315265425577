function setupGifHover() {
  var gifContainers = document.querySelectorAll(".js-article-image-animation");

  gifContainers.forEach(function (container) {
    var staticGif = container.querySelector(".js-animation-static");
    var animatedGif = container.querySelector(".js-animation-playing");

    container.addEventListener("mouseenter", function () {
      if (window.innerWidth >= 768) {
        staticGif.style.display = "none";
        animatedGif.style.display = "block";
      }
    });

    container.addEventListener("mouseleave", function () {
      if (window.innerWidth >= 768) {
        staticGif.style.display = "block";
        animatedGif.style.display = "none";
      }
    });
  });
}

function adjustGifDisplayForMobile() {
  var animatedGifs = document.querySelectorAll(
    ".js-article-image-animation .js-animation-playing"
  );
  var staticGifs = document.querySelectorAll(
    ".js-article-image-animation .js-animation-static"
  );

  if (window.innerWidth < 768) {
    // For mobile view, always show the animated GIF
    animatedGifs.forEach(function (animatedGif) {
      animatedGif.style.display = "block";
    });

    staticGifs.forEach(function (staticGif) {
      staticGif.style.display = "none";
    });
  } else {
    // For non-mobile view, clear inline styles so CSS hover effects take control
    animatedGifs.forEach(function (animatedGif) {
      animatedGif.style.display = "";
    });

    staticGifs.forEach(function (staticGif) {
      staticGif.style.display = "";
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  setupGifHover();
  adjustGifDisplayForMobile();
});

window.addEventListener("resize", adjustGifDisplayForMobile);

import "@grubersjoe/slide-menu";
var _ = require("lodash");

/**
 * Mobile menu triggered on SlideMenu
 *
 * @return {void}
 */
$(document).ready(function ($) {
  // Init vars for translations
  var backToText = null;
  // Check which language
  if (siteLang === "NO") {
    backToText = "Tilbake til ";
  } else {
    backToText = "Tillbaka till ";
  }
  const menuElement = document.getElementById("slide-menu-mobile");
  const menu = new SlideMenu(menuElement, {
    position: "left",
    showBackLink: true,
    submenuLinkAfter: '<span class="submenu-link-after"></span>',
    // backLinkBefore: '<span class="submenu-link-before"></span>' + backToText,
  });

  // When menu is opened
  menuElement.addEventListener("sm.open", () => {
    menuElement.classList.add("slide-menu--active");
  });

  // When menu is closed
  menuElement.addEventListener("sm.close", () => {
    menuElement.classList.remove("slide-menu--active");
  });

  $(
    "ul.main-menu > li > .megamenu-wrap > .layout.wide > ul.sub-menu > li.hide-top-level > a"
  ).addClass("hidden");
  $("<div class='menu-divider'></div>").insertBefore("li.inspiration");
  $("<div class='menu-divider'></div>").insertAfter("li.sex-education");

  // Only on swedish site
  if (siteLang === "SE") {
    // Add class to mobile menu object before menu-divider
    $("#nav-icon").on("click", function (e) {
      $(".menu-divider").map(function (e) {
        return $(this)
          .prevAll(".menu-item")
          .first()
          .addClass("no-border-bottom");
      });
    });
  }

  $(".menu-item-has-children").click(function () {
    const currentActiveMenuItem = $(this);
    $(".slide-menu__slider").css("transform", "translate(0)");

    /**
     * Check if the menu item is already active
     * If its already active close it
     */
    if (currentActiveMenuItem.children(".sub-menu").hasClass("open-submenu")) {
      $(this).find("a .slide-menu__decorator").removeClass("rotate");
      $(this).children(".sub-menu").removeClass("open-submenu", [0.15]);
    } else {
      // Close all the existing active dropdown menu items
      $(".menu-item-has-children").each(function () {
        if ($(this) !== currentActiveMenuItem) {
          $(this).find("a .slide-menu__decorator").removeClass("rotate");
          $(this).children(".sub-menu").removeClass("open-submenu", [0.15]);
        }
      });

      // Open current dropdown menu item
      $(this).find("a .slide-menu__decorator").toggleClass("rotate");
      $(this).children(".sub-menu").toggleClass("open-submenu", [0.15]);
      // $(this).children(".sub-menu").css("max-height", "415px");
    }
  });

  $(".sub-menu li").first().remove();

  if ($("body").hasClass("home")) {
    $(".header-main-wrap").css("background-color", "transparent");
    $("#nav-icon span").css("background", "#fff");
    $(".relative-wrap h3").css("color", "#fff");
    $(".language-switcher").css("color", "#ffffff");
    $(".search-trigger-symbol path").css("stroke", "#fff");
    $(".home-link-black").css("display", "none");
    $(".home-link-white").css("display", "block");
    $(window).on("scroll touchmove", function () {
      if ($(window).width() > 550) {
        if ($(document).scrollTop() > 10) {
          $(".header-main-wrap").css("background-color", "#fcf7e9");
          $("#nav-icon span").css("background", "#5c0f37");
          $(".relative-wrap h3").css("color", "#5c0f37");
          $(".search-trigger-symbol path").css("stroke", "#5c0f37");
          $(".home-link-white").css("display", "none");
          $(".home-link-black").css("display", "block");
          $(".language-switcher").css("color", "#5c0f37");
        } else {
          $(".header-main-wrap").css("background-color", "transparent");
          $("#nav-icon span").css("background", "#ffffff");
          $(".relative-wrap h3").css("color", "#ffffff");
          $(".search-trigger-symbol path").css("stroke", "#ffffff");
          $(".home-link-black").css("display", "none");
          $(".home-link-white").css("display", "block");
          $(".language-switcher").css("color", "#ffffff");
        }
      }
    });
  }
});

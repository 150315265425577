import "owl.carousel";
// ------------------------------------------------
// Fullwidth slider
// ------------------------------------------------

$(document).ready(function () {
  $(".slider-fullwidth").each(function () {
    var carousel = $(this);
    var itemsCount = carousel.children().length;

    carousel.owlCarousel({
      navigation: true,
      slideSpeed: 300,
      paginationSpeed: 400,
      items: 1,
      autoplay: true,
      animateOut: "fadeOut",
      autoPlaySpeed: 5000,
      autoPlayTimeout: 5000,
      autoplayHoverPause: true,
      loop: true,
      // Set dragging options based on the number of items
      mouseDrag: itemsCount > 1,
      touchDrag: itemsCount > 1,
    });
  });
});
